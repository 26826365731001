import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <h1
      css={`
        text-align: center;
      `}
    >
      404 NOT FOUND
    </h1>
  </Layout>
);

export default NotFoundPage;
